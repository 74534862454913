@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}
.dark-mode {
  background-color: black;
}
.root,
#root,
#docs-root {
  --primary-color: #3b3b3b;
  --secondry-color: #f5881f;
  --third-color: #ebebeb;
  --fourth-color: #f3f2f2;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #f5881f;
  border-radius: 50px;
}

section {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.container {
  position: relative;
  margin-left: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards {
  width: 150px;
}

.card {
  width: 220px;
  height: 400px;
  background: #999;
  border: 1px solid #000;
  position: absolute;
  transform-origin: bottom center;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content h1 {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content h1:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Style for the dropdown button */
.dropbtn {
  font-size: 16px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  transition: all 0.3s ease-in-out;
}

input:focus {
  outline: none;
}

.slide-special-event {
  position: relative;

  width: 100%;

  padding: 20px;
}

.show {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: fade 1.5s ease-in-out;
}

.show-special-event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: fade 1.5s ease-in-out;
}

.show > div {
  width: 100%;
  height: 100%;
}
.show-special-event > div {
  width: 100%;
  height: 100%;
}

img.slider-image {
  width: 100%;
  height: 100%;
}

.not-show {
  display: none;
}
.not-show-organizer {
  display: none;
}

.prev {
  position: absolute;
  top: 50%;
  left: 1px;
  transform: translateY(-50%);
}

.next {
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
}

.prev-special-event {
  transform: rotate(90deg);
}

.next-special-event {
  transform: rotate(90deg);
}

.page {
  text-align: center;
}
.dot {
  cursor: pointer;
  height: 5px;
  width: 20px;
  margin: 0 2px;
  background-color: #ddd;
  display: inline-flex;
  transition: background-color 0.6s ease;
}

button:hover,
.dot:hover,
.active {
  opacity: 1;
}

.swiper-button-prev {
  margin-left: 15px;
  margin-right: 15px;
}
.swiper-button-next {
  margin-left: 15px;
  margin-right: 15px;
}
.swiper-button-prev::after {
  background-color: rgb(180, 178, 178) !important;
  border-radius: 100%;
  padding: 30px;
  font-size: 20px !important;
  color: white !important;
  transition: 0.5s ease-in-out;
  content: "" !important;
  display: flex;
  background-image: url("./assets/icons/ArrowLeftSlider.svg");
  background-size: 50%;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position: center;
}

.swiper-button-prev:hover::after {
  background-color: rgb(77, 74, 74) !important;
  transition: 0.5s ease-in-out;
}

.swiper-button-next::after {
  background-color: rgb(180, 178, 178) !important;
  border-radius: 100%;
  padding: 30px;

  font-size: 20px !important;
  color: white !important;
  transition: 0.5s ease-in-out;
  content: "" !important;
  display: flex;
  background-image: url("./assets/icons/ArrowRightSlider.svg");
  background-size: 50%;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position: center;
}
.swiper-button-next:hover::after {
  background-color: rgb(77, 74, 74) !important;
  transition: 0.5s ease-in-out;
}

#MultiImage {
  padding-left: 60px;
  padding-right: 30px;
  display: flex;
  justify-content: space-around;
}
.carousel-container {
  display: flex;
  height: 100vh;
}

.carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
  width: 100vw;
}

.react-rating-stars-svg {
  margin-bottom: 10px;
}

.swiper-pagination-bullet {
  background-color: gray !important;
}

.review-rate .css-1c99szj-MuiRating-icon {
  color: var(--secondry-color) !important;
  font-size: 25px !important;
}

.example::-webkit-scrollbar {
  background-color: red;
}

.example::-webkit-scrollbar {
  background-color: rgba(190, 190, 190, 0.1);
  width: 8px;
}
.example::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 50px;
}
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #f5881f;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.logo-loader {
  animation: logoLoader 2s infinite;
}
@keyframes logoLoader {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
 

.organizer-reviews::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 5px;
}
.organizer-reviews {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  transition: scroll-left 0.2s ease;
}
@media (max-width: 600px) {
  section {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  img.slider-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .organizer-reviews::-webkit-scrollbar {
    height: 3px;
  }
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background-color: #949090;
  border-radius: 100%;
  padding: 10px;
}

.rbc-day-bg:not(.ant-picker-cell-disabled) {
  border-radius: 4px;
  margin: 10px;
}
.rbc-month-row:nth-child(1)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(1) {
  background: rgba(245, 135, 31, 0.029) !important;
}
.rbc-month-row:nth-child(1)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(2) {
  background: rgba(245, 135, 31, 0.058) !important;
}
.rbc-month-row:nth-child(1)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(3) {
  background: rgba(245, 135, 31, 0.087) !important;
}
.rbc-month-row:nth-child(1)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(4) {
  background: rgba(245, 135, 31, 0.116) !important;
}
.rbc-month-row:nth-child(1)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(5) {
  background: rgba(245, 135, 31, 0.145) !important;
}
.rbc-month-row:nth-child(1)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(6) {
  background: rgba(245, 135, 31, 0.174) !important;
}
.rbc-month-row:nth-child(1)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(7) {
  background: rgba(245, 135, 31, 0.203) !important;
}

.rbc-month-row:nth-child(2)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(1) {
  background: rgba(245, 135, 31, 0.058) !important;
}
.rbc-month-row:nth-child(2)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(2) {
  background: rgba(245, 135, 31, 0.116) !important;
}
.rbc-month-row:nth-child(2)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(3) {
  background: rgba(245, 135, 31, 0.174) !important;
}
.rbc-month-row:nth-child(2)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(4) {
  background: rgba(245, 135, 31, 0.232) !important;
}
.rbc-month-row:nth-child(2)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(5) {
  background: rgba(245, 135, 31, 0.29) !important;
}
.rbc-month-row:nth-child(2)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(6) {
  background: rgba(245, 135, 31, 0.348) !important;
}
.rbc-month-row:nth-child(2)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(7) {
  background: rgba(245, 135, 31, 0.406) !important;
}

.rbc-month-row:nth-child(3)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(1) {
  background: rgba(245, 135, 31, 0.087) !important;
}
.rbc-month-row:nth-child(3)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(2) {
  background: rgba(245, 135, 31, 0.174) !important;
}
.rbc-month-row:nth-child(3)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(3) {
  background: rgba(245, 135, 31, 0.261) !important;
}
.rbc-month-row:nth-child(3)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(4) {
  background: rgba(245, 135, 31, 0.348) !important;
}
.rbc-month-row:nth-child(3)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(5) {
  background: rgba(245, 135, 31, 0.435) !important;
}
.rbc-month-row:nth-child(3)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(6) {
  background: rgba(245, 135, 31, 0.522) !important;
}
.rbc-month-row:nth-child(3)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(7) {
  background: rgba(245, 135, 31, 0.609) !important;
}

.rbc-month-row:nth-child(4)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(1) {
  background: rgba(245, 135, 31, 0.116) !important;
}
.rbc-month-row:nth-child(4)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(2) {
  background: rgba(245, 135, 31, 0.232) !important;
}
.rbc-month-row:nth-child(4)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(3) {
  background: rgba(245, 135, 31, 0.348) !important;
}
.rbc-month-row:nth-child(4)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(4) {
  background: rgba(245, 135, 31, 0.464) !important;
}
.rbc-month-row:nth-child(4)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(5) {
  background: rgba(245, 135, 31, 0.58) !important;
}
.rbc-month-row:nth-child(4)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(6) {
  background: rgba(245, 135, 31, 0.696) !important;
}
.rbc-month-row:nth-child(4)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(7) {
  background: rgba(245, 135, 31, 0.812) !important;
}

.rbc-month-row:nth-child(5)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(1) {
  background: rgba(245, 135, 31, 0.145) !important;
}
.rbc-month-row:nth-child(5)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(2) {
  background: rgba(245, 135, 31, 0.29) !important;
}
.rbc-month-row:nth-child(5)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(3) {
  background: rgba(245, 135, 31, 0.435) !important;
}
.rbc-month-row:nth-child(5)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(4) {
  background: rgba(245, 135, 31, 0.58) !important;
}
.rbc-month-row:nth-child(5)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(5) {
  background: rgba(245, 135, 31, 0.725) !important;
}
.rbc-month-row:nth-child(5)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(6) {
  background: rgba(245, 135, 31, 0.87) !important;
}
.rbc-month-row:nth-child(5)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(7) {
  background: rgb(245, 135, 31) !important;
}

.rbc-month-row:nth-child(6)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(1) {
  background: rgba(245, 135, 31, 0.174) !important;
}
.rbc-month-row:nth-child(6)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(2) {
  background: rgba(245, 135, 31, 0.348) !important;
}
.rbc-month-row:nth-child(6)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(3) {
  background: rgba(245, 135, 31, 0.522) !important;
}
.rbc-month-row:nth-child(6)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(4) {
  background: rgba(245, 135, 31, 0.696) !important;
}
.rbc-month-row:nth-child(6)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(5) {
  background: rgba(245, 135, 31, 0.87) !important;
}
.rbc-month-row:nth-child(6)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(6) {
  background: rgb(245, 135, 31) !important;
}
.rbc-month-row:nth-child(6)
  .rbc-day-bg:not(.ant-picker-cell-disabled):nth-child(7) {
  background: rgb(245, 135, 31) !important;
} /*# sourceMappingURL=style.css.map */

.rbc-row-content {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.rbc-date-cell {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}
.rbc-date-cell button {
  font-size: 2rem;
  margin: 1rem;
}
.rbc-month-row + .rbc-month-row {
  border-top: none !important;
}
.rbc-day-bg .rbc-off-range-bg {
  border: none !important;
}
.rbc-header {
  background: rgba(245, 135, 31, 0.232) !important;
  margin: 0px 0.5rem;
  border-radius: 5px;
  border: none !important;
}
.rbc-month-view {
  border: none !important;
}

 
.rbc-toolbar {
  justify-content: flex-start !important;
}
.rbc-toolbar button {
  background: #f5871f82 !important;
  margin: 0px 0.5rem 1rem !important;
  border: none !important;

 
}
@media (max-width: 600px) {
  .rbc-day-bg:not(.ant-picker-cell-disabled) {
    margin: 0;
    border-radius: 0;
  }
  .rbc-date-cell button {
    font-size: 0.8rem;
    margin: 0.5rem;
  }
  .rbc-date-cell {
    width: 100%;
  }
}


